import React from 'react'
import { Link, graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from '../../layouts/blog'
import styles from '../../styles'
import presets from '../../utils/presets'
import { rhythm, scale } from '../../utils/typography'

class BlogPage extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const { twitter } = this.props.data.site.siteMetadata.social

    return (
      <Layout location={this.props.location}>
        <div>
          <h1
            css={{
              ...scale(4 / 5),
              fontWeight: `800`,
              marginBottom: rhythm(2)
            }}
          >
            Personal blog by{' '}
            <a
              href={`https://twitter.com/${twitter}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Andrey Teologov
            </a>
          </h1>
          <ul
            css={{
              marginBottom: rhythm(2),
              marginTop: rhythm(2),
              marginLeft: 0,
              listStyle: `none`
            }}
          >
            {posts.map(post => (
              <li key={post.node.fields.slug}>
                <span
                  css={{
                    color: styles.colors.light,
                    display: `block`,
                    [presets.Tablet]: {
                      float: `right`,
                      marginLeft: `1rem`
                    }
                  }}
                >
                  {post.node.frontmatter.date}
                </span>
                <h2>
                  <Link to={post.node.fields.slug} className="link-underline">
                    {post.node.frontmatter.title}
                  </Link>
                </h2>
              </li>
            ))}
          </ul>
        </div>
      </Layout>
    )
  }
}

BlogPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object
}

export default BlogPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        social {
          twitter
        }
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { ne: true } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
